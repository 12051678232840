import { ChangeEvent, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import styled from 'styled-components'

type ModalProps = {
  isModalOpen: boolean
  onCancel: () => void
  onAddConfig: (market: string, brand: string) => void
}

const StyledModalFooter = styled(IWModalFooter)`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`

const Spacer = styled.div`
  margin: 1rem;
`

const CreateOrgConfigModal = ({
  isModalOpen,
  onCancel,
  onAddConfig,
}: ModalProps) => {
  const { t } = useTranslation()
  const [market, setMarket] = useState('')
  const [brand, setBrand] = useState('')

  const hasMarketError = () => market.length < 3 || market.length > 10
  const hasBrandError = () => brand.length < 1 || brand.length > 20

  const handleClose = () => {
    setMarket('')
    setBrand('')
    onCancel()
  }

  const handleCreate = () => {
    onAddConfig(market, brand)
    setMarket('')
    setBrand('')
  }

  return (
    <IWModal open={isModalOpen} onClose={onCancel}>
      <IWModalHeader>
        <IWTypography size="lg" weight="medium">
          {t('loadScheduling.configurationsPage.addModalLabel')}
        </IWTypography>
      </IWModalHeader>

      <IWModalContent>
        <IWTextInput
          label={t('loadScheduling.configurationsPage.addModalISOLabel')}
          maxLength={10}
          required
          hasError={hasMarketError()}
          value={market}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setMarket(e.target.value)
          }}
        />

        <Spacer />

        <IWTextInput
          label={t('loadScheduling.configurationsPage.addModalBrandLabel')}
          maxLength={20}
          required
          value={brand}
          hasError={hasBrandError()}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setBrand(e.target.value)
          }}
        />
      </IWModalContent>
      <StyledModalFooter>
        <IWButton color="grey" variant="outline" onClick={handleClose}>
          {t('button.cancel')}
        </IWButton>
        <IWButton
          disabled={hasMarketError() || hasBrandError()}
          onClick={handleCreate}
        >
          {t('button.create')}
        </IWButton>
      </StyledModalFooter>
    </IWModal>
  )
}

export default CreateOrgConfigModal
