import { useEffect, useRef, useState } from 'react'

export const useElementHeight = () => {
  const elementRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number>(0)

  const pixelsToRem = (pixels: number): number => {
    const rootFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    )
    return pixels / rootFontSize
  }

  useEffect(() => {
    if (!elementRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      if (!elementRef.current) {
        return
      }

      const currentFullHeightInPX =
        elementRef.current.getBoundingClientRect().height

      setHeight(currentFullHeightInPX)
    })

    resizeObserver.observe(elementRef.current)

    return () => resizeObserver.disconnect()
  }, [])

  return { elementRef, height, pixelsToRem }
}
