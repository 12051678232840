import {
  faCalendarAlt,
  faCog,
  faShareNodes,
  faSignOutAlt,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import IWContextMenu from 'shared/components/thunderbolt/IWContextMenu'
import IWContextMenuItem from 'shared/components/thunderbolt/IWContextMenuItem'
import IWDivider from 'shared/components/thunderbolt/IWDivider'
import IWVerticalNavItem from 'shared/components/thunderbolt/IWVerticalNavItem'
import UserContext from 'shared/contexts/UserContext'
import { isPermissionAvailable } from 'shared/helpers'
import styled from 'styled-components'

interface ExtraProps {
  onLogout?: () => void
  isAdmin?: boolean
}

type Props = ExtraProps & React.ComponentPropsWithoutRef<'button'>

const IWSectionDivider = styled(IWDivider)`
  margin: 0;
`

const IWSettingTile = ({ onLogout }: Props) => {
  const { t } = useTranslation()
  const { availableToolPolicies } = useContext(UserContext)

  const canViewLoadScheduling = isPermissionAvailable(
    availableToolPolicies,
    'loadScheduling:editor',
  )

  return (
    <IWContextMenu
      positions={['right', 'bottom']}
      menu={[
        <IWContextMenuItem
          as="routerNavLink"
          key="user-profile"
          data-testid="user-tile-profile"
          label={t('systemPreferences.title.userProfile')}
          icon={faUser}
          to="/system-preferences/user"
        />,
        <IWContextMenuItem
          as="routerNavLink"
          key="integrations-settings"
          data-testid="user-tile-integrations-settings"
          label={t('systemPreferences.title.integrations')}
          icon={faShareNodes}
          to="/system-preferences/integrations"
        />,
        canViewLoadScheduling ? (
          <IWContextMenuItem
            as="routerNavLink"
            key="load-scheduling-settings"
            data-testid="user-tile-load-scehduling-settings"
            label={t('systemPreferences.title.loadScheduling')}
            icon={faCalendarAlt}
            to="/system-preferences/load-scheduling"
          />
        ) : (
          <></>
        ),
        <IWSectionDivider key="divider-2" />,
        <IWContextMenuItem
          key="logout"
          data-testid="user-tile-logout"
          label={t('userTile.labels.signout')}
          icon={faSignOutAlt}
          onClick={onLogout}
        />,
      ]}
    >
      <IWVerticalNavItem label="" icon={faCog} onClick={() => {}} />
    </IWContextMenu>
  )
}

export default IWSettingTile
