import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { DateTime } from 'luxon'
import { SyntheticEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Popover } from 'react-tiny-popover'
import useIWRelativeDatePicker, {
  DefaultState as RelativeDatePickerDefaultState,
  ReturnProps as UseRelativeDatePickerProps,
} from 'shared/hooks/useIWRelativeDatePicker'
import getDatePickerDisplayString from 'shared/utils/getDatePickerDisplayString'
import styled, { useTheme } from 'styled-components'
import IWButton from './IWButton'
import IWGeneralBadge from './IWGeneralBadge'
import IWRelativeDatePicker from './IWRelativeDatePicker'

interface IWFilterBadgesDatetimeProps {
  id: string
  onConfirm: (id: string, state: UseRelativeDatePickerProps) => void
  onRemove: (id: string) => void
  onCancel: (id: string) => void
  isOpen: boolean
  onClick: (e: SyntheticEvent) => void
  disabledRelativeOptions?: RelativeDatePickerDefaultState['disabledRelativeOptions']
  disabledMainOptions?: RelativeDatePickerDefaultState['disabledMainOptions']
}

interface Props extends UseRelativeDatePickerProps {
  label?: string
  onConfirm?: () => void
  onCancel?: () => void
  onPlaceholderClick?: () => void
  isOpen: boolean
  hasError?: boolean
  children: any
}

const StyledPopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.palette.grey[0]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 1rem;
  min-width: 400px;
`

const StyledButtonDiv = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`

const IWFilterBadgeDatetimeWrapper = ({
  onConfirm,
  onCancel,
  isOpen,
  children,
  ...state
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Popover
      padding={4}
      reposition
      positions={['bottom']}
      align="start"
      containerStyle={{ zIndex: theme.layers.popover }}
      isOpen={isOpen}
      onClickOutside={onCancel}
      content={() => {
        return (
          <StyledPopupDiv onClick={(e) => e.stopPropagation()}>
            <IWRelativeDatePicker
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...state}
            />
            <StyledButtonDiv>
              <IWButton variant="alternative" onClick={onCancel}>
                {t(`button.cancel`)}
              </IWButton>
              <IWButton
                data-testid="relative-date-picker-confirm"
                disabled={state.selectedMainOption === undefined}
                onClick={onConfirm}
              >
                {t(`button.confirm`)}
              </IWButton>
            </StyledButtonDiv>
          </StyledPopupDiv>
        )
      }}
    >
      {children}
    </Popover>
  )
}

const IWFilterBadgesDatetime = ({
  id,
  onConfirm,
  onRemove,
  onClick,
  onCancel,
  isOpen,
  disabledRelativeOptions,
  disabledMainOptions,
}: IWFilterBadgesDatetimeProps) => {
  const { t } = useTranslation()
  const [summary, setSummary] = useState<string | undefined>()
  const state = useIWRelativeDatePicker({
    selectedMainOption: undefined,
    selectedTimeLengthOption: { value: 'days' },
    selectedRelativeOption: { value: 'today' },
    selectedSpecificDatePickerDate: DateTime.local(),
    selectedDatePickerMin: DateTime.local(),
    selectedDatePickerMax: DateTime.local(),
    timeLengthValue: 30,
    disabledRelativeOptions,
    disabledMainOptions,
  })

  const defaultsRef = useRef<RelativeDatePickerDefaultState>({
    selectedMainOption: undefined,
    selectedTimeLengthOption: { value: 'days' },
    selectedRelativeOption: { value: 'today' },
    selectedSpecificDatePickerDate: DateTime.local(),
    selectedDatePickerMin: DateTime.local(),
    selectedDatePickerMax: DateTime.local(),
    timeLengthValue: 30,
  })

  const badgeName = id.replaceAll(/_/g, ' ')

  const handleConfirm = () => {
    defaultsRef.current = {
      selectedMainOption: state.selectedMainOption,
      selectedTimeLengthOption: state.selectedTimeLengthOption,
      selectedRelativeOption: state.selectedRelativeOption,
      selectedSpecificDatePickerDate: state.selectedSpecificDatePickerDate,
      selectedDatePickerMin: state.selectedDatePickerMin,
      selectedDatePickerMax: state.selectedDatePickerMax,
      timeLengthValue: state.timeLengthValue,
      disabledRelativeOptions: state.disabledRelativeOptions,
    }
    const summaryString = getDatePickerDisplayString(state, t)
    onConfirm(id, state)
    setSummary(summaryString)
  }

  const handleCancel = () => {
    state.modifyFullState(defaultsRef.current)
    const summaryString = getDatePickerDisplayString(defaultsRef.current, t)
    setSummary(summaryString)
    onCancel(id)
  }
  return (
    <IWFilterBadgeDatetimeWrapper
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...state}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    >
      <IWGeneralBadge
        label={summary ? `${badgeName}: ${summary}` : badgeName}
        color="grey"
        icon={faTimesCircle}
        iconPosition="trailing"
        onClick={onClick}
        onIconClick={() => onRemove(id)}
      />
    </IWFilterBadgeDatetimeWrapper>
  )
}

export default IWFilterBadgesDatetime
