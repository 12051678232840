import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import {
  faCaretDown,
  faCaretRight,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink } from 'react-router-dom'
import IWTypography from './IWTypography'

type ElementProps =
  | ({ as?: 'button' } & React.ComponentPropsWithoutRef<'button'>)
  | ({ as: 'a' } & React.ComponentPropsWithoutRef<'a'>)
  | ({ as: 'routerLink' } & React.ComponentPropsWithoutRef<typeof Link>)
  | ({ as: 'routerNavLink' } & React.ComponentPropsWithoutRef<typeof NavLink>)

interface VerticalNavProps {
  /** Label to display as the main menu item content */
  label: string
  /** Optional leading icon to show */
  icon?: IconDefinition
  /** Optional value to show as a badge (zero or negative values will not show) */
  badgeValue?: number
  /** Optional to show the drawer icon on the right, indicating that this opens drawer panel when clicked */
  drawer?: boolean
  /** Is the menu element is active */
  active?: boolean
  /** If the sidebar is collapsed or not */
  collapsed?: boolean
  /** If the sidebar is expanded or not (Only if item is a submenu item) */
  expanded?: boolean
}

type Props = VerticalNavProps & ElementProps

const LeadingIconWrapper = styled.div<{
  collapsed?: boolean
  active?: boolean
}>`
  margin: ${(props) => (props.collapsed ? '0' : '0 0.25rem 0 0.25rem')};
  color: ${(props) =>
    props.active
      ? props.theme.palette.primary[700]
      : props.theme.palette.grey[400]};
  display: flex;
  font-size: 1rem;
  align-items: center;
`

function getBadgeBackgroundColor({
  active,
  count,
  theme,
}: {
  active?: boolean
  count: number
  theme: DefaultTheme
}) {
  if (active) {
    return theme.palette.primary[700]
  }
  if (count > 0) {
    return theme.palette.primary[50]
  }
  return theme.palette.grey[200]
}

function getBadgeFontColor({
  active,
  count,
  theme,
}: {
  active?: boolean
  count: number
  theme: DefaultTheme
}) {
  if (active) {
    return theme.palette.grey[0]
  }
  if (count > 0) {
    return theme.palette.primary[700]
  }
  return theme.palette.grey[700]
}

const BadgeIconWrapper = styled.div<{
  drawer?: boolean
  active?: boolean
  count: number
}>`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.drawer ? '0 1rem 0 0.5rem' : '0 0 0 0.5rem')};
  padding: 0.125rem 0.5rem;
  color: ${(props) => getBadgeFontColor(props)};
  font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  background-color: ${(props) => getBadgeBackgroundColor(props)};
  border-radius: 2rem;
`

const DrawerIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem 0 0;
  min-width: 0.5rem;
  color: ${(props) => props.theme.palette.grey[400]};
`

const StyledIWTypography = styled(IWTypography)`
  flex-grow: 1;
  margin: 0 0.25rem 0 0.25rem;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};

  &:hover {
    opacity: ${(props) => (props.onClick ? '0.5' : '1')};
  }
`

const StyledButton = styled.button<{
  active: boolean
}>`
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  text-decoration: none;
  border: ${(props) =>
    props.active
      ? `1px solid ${props.theme.palette.grey[200]}`
      : '1px solid transparent'};
  background: ${(props) =>
    props.active ? props.theme.palette.grey[50] : 'transparent'};
  color: ${(props) =>
    props.active
      ? props.theme.palette.primary[700]
      : props.theme.palette.grey[600]};
  text-transform: capitalize;
  padding: 0.5rem 0.5rem 0.5rem 0.25rem;
  border-radius: 0.6rem;

  &:hover {
    background-color: ${(props) =>
      props.active
        ? props.theme.palette.grey[50]
        : props.theme.palette.grey[200]};
  }

  &:hover ${BadgeIconWrapper} {
    background-color: ${(props) =>
      props.active
        ? props.theme.palette.primary[700]
        : props.theme.palette.grey[0]};
  }
`

/**
 * This is the nav item intended for use in the main side nav of the app
 * The elements can be customized per their props listed here but can be further customized
 * by setting the 'as' prop to a, routerLink and button and forwarding any extra props to the corresponding element
 */
const IWVerticalNavItem = React.forwardRef(
  (
    {
      label,
      icon,
      badgeValue,
      drawer,
      active,
      as,
      collapsed,
      expanded = false,
      ...props
    }: Props,
    ref: React.Ref<HTMLButtonElement> | React.Ref<HTMLAnchorElement>,
  ) => {
    let tag
    if (as === 'routerLink') {
      tag = Link
    } else if (as === 'routerNavLink') {
      tag = NavLink
    } else {
      tag = as
    }
    return (
      <StyledButton
        data-testid="vertical-nav-item"
        as={tag}
        ref={ref}
        active={!!active}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {icon && (
          <LeadingIconWrapper
            active={
              active || (Boolean(badgeValue) && (badgeValue as number) > 0)
            }
          >
            <StyledFontAwesomeIcon icon={icon} />
          </LeadingIconWrapper>
        )}
        {!collapsed && label && (
          <StyledIWTypography
            inheritFontColor
            as="span"
            size="sm"
            weight="medium"
          >
            {label}
          </StyledIWTypography>
        )}
        {!collapsed && badgeValue && (
          <BadgeIconWrapper
            drawer={drawer}
            active={active}
            count={badgeValue}
            className="badge-wrapper"
          >
            <IWTypography as="span" size="xs" weight="medium" inheritFontColor>
              {badgeValue}
            </IWTypography>
          </BadgeIconWrapper>
        )}

        {drawer && !collapsed && (
          <DrawerIconWrapper>
            {!expanded && <StyledFontAwesomeIcon icon={faCaretRight} />}
            {expanded && <StyledFontAwesomeIcon icon={faCaretDown} />}
          </DrawerIconWrapper>
        )}
      </StyledButton>
    )
  },
)

export default IWVerticalNavItem
