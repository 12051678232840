import {
  faCirclePlus,
  faCloudDownload,
  faCog,
  faCopy,
  faEdit,
  faEllipsisVertical,
  faTrashCanCheck,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { orderBy } from 'lodash'
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWContextMenu from 'shared/components/thunderbolt/IWContextMenu'
import IWContextMenuItem from 'shared/components/thunderbolt/IWContextMenuItem'
import IWContextDivider from 'shared/components/thunderbolt/IWContextMenuItemDivider'
import IWTable from 'shared/components/thunderbolt/IWTable'
import IWToggle from 'shared/components/thunderbolt/IWToggle'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { downloadPfx } from 'shared/helpers'
import useCopyText from 'shared/hooks/useCopyText'
import {
  createOrgCredential,
  deleteOrgCredential,
  getCredentials,
  OrgCredential,
  toggleActiveOrgCredential,
  updateOrgCredential,
} from 'shared/loadSchedulingClient'
import styled from 'styled-components'
import CreateOrgCredentialModal, {
  CreateOrgCredential,
} from 'systemPreferences/components/LoadScheduling/CreateOrgCredentialModal'
import DeleteOrgCredentialModal from 'systemPreferences/components/LoadScheduling/DeleteOrgCredentialModal'
import UpdateOrgCredentialModal, {
  UpdateOrgCredential,
} from 'systemPreferences/components/LoadScheduling/UpdateOrgCredentialModal'

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionHeader = styled(IWTypography)`
  display: inline-block;
`

const HeaderWithButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledDesc = styled(IWTypography)`
  text-align: left;
  color: ${(props) => props.theme.palette.grey[400]};
  margin-bottom: 1rem;
`

type SectionProps = {
  header: string
  buttonLabel: string
  onButtonClick: (e: SyntheticEvent) => void
  children: ReactNode
}

const SectionHeaderComponent = ({
  header,
  buttonLabel,
  onButtonClick,
  children,
}: SectionProps) => {
  return (
    <StyledSection>
      <HeaderWithButton>
        <SectionHeader
          as="h1"
          size="lg"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
        >
          {header}
        </SectionHeader>
        <IWButton
          icon={faCirclePlus}
          iconPosition="leading"
          onClick={onButtonClick}
        >
          {buttonLabel}
        </IWButton>
      </HeaderWithButton>
      {children}
    </StyledSection>
  )
}

const tableColumns = [
  {
    title: 'Market',
    accessor: 'market',
    align: 'left',
  },
  {
    title: 'Brand',
    accessor: 'brand',
    align: 'left',
  },
  {
    title: 'Zone',
    accessor: 'zone',
    align: 'left',
  },
  {
    title: 'Location Name',
    accessor: 'locationName',
    align: 'left',
  },
  {
    title: 'Location',
    accessor: 'location',
    align: 'left',
  },
  {
    title: 'User ID',
    accessor: 'userId',
    align: 'left',
  },
  {
    title: 'User Account',
    accessor: 'userAccount',
    align: 'left',
  },
  {
    title: 'Sub-account',
    accessor: 'userSubAccount',
    align: 'left',
  },
  {
    title: 'PFX',
    accessor: 'todo-here',
    align: 'left',
  },
  {
    title: 'Is SUMA',
    accessor: 'isSuma',
    align: 'left',
  },
  {
    title: 'Enable',
    accessor: 'enabled',
    align: 'center',
  },
  {
    title: 'Actions',
    accessor: 'contextMenu',
    align: 'center',
  },
]

export default function LsCredentials() {
  const useQueryKey = 'ls-credentials'
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { copyText } = useCopyText()
  const [searchParams] = useSearchParams()
  const [items, setItems] = useState<unknown[]>([])
  const [isCreateCredentialModalOpen, setIsCreateCredentialModalOpen] =
    useState(false)
  const [isUpdateCredentialModalOpen, setIsUpdateCredentialModalOpen] =
    useState(false)
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] =
    useState(false)
  const [currentViewableConfigId, setCurrentViewableConfigId] = useState<
    string | null
  >(null)
  const [credentialToUpdate, setCredentialToUpdate] = useState<OrgCredential>()
  const [credentialIdToDelete, setCredentialIdToDelete] = useState<
    string | undefined
  >(undefined)

  const { data: credentials, isLoading } = useQuery({
    queryKey: [useQueryKey, currentViewableConfigId],
    queryFn: async () => {
      return getCredentials(true)
    },
  })

  const enableToggleMutation = useMutation({
    mutationKey: [useQueryKey, 'toggle-enable'],
    mutationFn: ({
      credentialId,
      isActive,
    }: {
      credentialId: string
      isActive: boolean
    }) => {
      return toggleActiveOrgCredential(credentialId, isActive)
    },
    onSuccess: () => queryClient.invalidateQueries([useQueryKey]),
  })

  const createCredentialMutation = useMutation({
    mutationKey: [useQueryKey, 'create'],
    mutationFn: (credential: CreateOrgCredential) =>
      createOrgCredential(credential),
    onSuccess: () => queryClient.invalidateQueries([useQueryKey]),
  })

  const updateCredentialMutation = useMutation({
    mutationKey: [useQueryKey, 'update'],
    mutationFn: (credential: UpdateOrgCredential) =>
      updateOrgCredential(credential),
    onSuccess: () => queryClient.invalidateQueries([useQueryKey]),
  })

  const deleteCredentialMutation = useMutation({
    mutationKey: [useQueryKey, 'delete'],
    mutationFn: (configId: string) => deleteOrgCredential(configId),
    onSuccess: () => queryClient.invalidateQueries([useQueryKey]),
  })

  const handleCredentialToUpdate = (id) => {
    const c = credentials?.find(({ credentialId }) => credentialId === id)
    setCredentialToUpdate(() => c)
    setIsUpdateCredentialModalOpen(true)
  }

  const mapper = () => {
    let filtered = credentials || []

    if (currentViewableConfigId) {
      filtered = filtered.filter((c) => c.configId === currentViewableConfigId)
    }

    return orderBy(filtered, [
      'market',
      'brand',
      'zone',
      'locationName',
      'location',
    ]).map((item, index) => ({
      market: item.market,
      brand: item.brand,
      zone: item.zone,
      locationName: item.locationName,
      location: item.location,
      userId: item.userId,
      userAccount: item.userAccount,
      userSubAccount: item.userSubAccount,
      pfx: item.pfx,
      isSuma: item.isSuma ? t('common.yes') : t('common.no'),
      enabled: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IWToggle
            id={index.toString()}
            checked={item.isActive}
            onChange={async () => {
              await enableToggleMutation.mutateAsync({
                credentialId: item.credentialId,
                isActive: !item.isActive,
              })
            }}
          />
        </div>
      ),
      contextMenu: (
        <IWContextMenu
          positions={['bottom', 'top']}
          menu={[
            <IWContextMenuItem
              as="routerLink"
              key={`ls-cred-view-configs-${item.credentialId}`}
              label={t('loadScheduling.contextMenus.viewConfigs')}
              icon={faCog}
              to={`/system-preferences/load-scheduling?tab=5&configId=${item.configId}`}
            />,

            <IWContextDivider />,

            <IWContextMenuItem
              as="button"
              key={`ls-cred-copy-pass-${item.credentialId}`}
              label={t('loadScheduling.contextMenus.copyUserPassword')}
              icon={faCopy}
              onClick={() => copyText(item?.userPassword || '')}
            />,

            <IWContextMenuItem
              as="button"
              key={`ls-cred-copy-pfx-pass-${item.credentialId}`}
              label={t('loadScheduling.contextMenus.copyPFXPassphrase')}
              icon={faCopy}
              onClick={() => copyText(item?.pfxPassphrase || '')}
            />,

            <IWContextMenuItem
              as="button"
              key={`ls-cred-download-pfx-${item.credentialId}`}
              label={t('loadScheduling.contextMenus.downloadPFX')}
              icon={faCloudDownload}
              onClick={() => {
                if (!item?.pfx) {
                  return
                }

                const filename = `${item.market}-${item.brand}-${item.location}`
                downloadPfx(item.pfx, filename)
              }}
            />,

            <IWContextDivider />,

            <IWContextMenuItem
              as="button"
              key={`ls-cred-update-${item.credentialId}`}
              data-testid={`ls-cred-update-${item.credentialId}`}
              label={t('button.update')}
              icon={faEdit}
              onClick={() => {
                handleCredentialToUpdate(item.credentialId)
              }}
            />,

            <IWContextMenuItem
              as="button"
              key={`ls-cred-delete-${item.credentialId}`}
              data-testid={`ls-cred-delete-${item.credentialId}`}
              label={t('button.delete')}
              icon={faTrashCanCheck}
              disabled={item.isActive}
              onClick={() => {
                setCredentialIdToDelete(item.credentialId)
                setIsDeleteCredentialModalOpen(true)
              }}
            />,
          ]}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </IWContextMenu>
      ),
    }))
  }

  useEffect(() => {
    const queryParamsConfigId = searchParams.get('configId')
    setCurrentViewableConfigId(queryParamsConfigId || '')
  }, [])

  useEffect(() => {
    setItems(mapper() || [])
  }, [credentials])

  return (
    <>
      <SectionHeaderComponent
        header={t('loadScheduling.credentialsPage.title')}
        buttonLabel={t('loadScheduling.credentialsPage.addCredentialLabel')}
        onButtonClick={() => {
          setIsCreateCredentialModalOpen(true)
        }}
      >
        <StyledDesc size="sm">
          {t('loadScheduling.credentialsPage.desc')}
        </StyledDesc>

        {Boolean(items?.length) && !isLoading && (
          <IWTable
            border
            isLoading={isLoading}
            data={items || []}
            columns={tableColumns}
          />
        )}
      </SectionHeaderComponent>

      <CreateOrgCredentialModal
        isModalOpen={isCreateCredentialModalOpen}
        onCancel={() => setIsCreateCredentialModalOpen(false)}
        onCreate={async (credential) => {
          if (Object.keys(credential).length) {
            await createCredentialMutation.mutateAsync(credential)
            setIsCreateCredentialModalOpen(false)
          }
        }}
      />

      {credentialToUpdate && (
        <UpdateOrgCredentialModal
          isModalOpen={isUpdateCredentialModalOpen}
          credentialToUpdate={credentialToUpdate}
          onCancel={() => setIsUpdateCredentialModalOpen(false)}
          onCreate={async (credential) => {
            if (Object.keys(credential).length) {
              const { userPassword, pfxPassphrase, ...rest } = credential

              let updatedCredential = rest as UpdateOrgCredential

              if (
                userPassword &&
                userPassword !== credentialToUpdate.userPassword
              ) {
                updatedCredential.userPassword = userPassword
              }

              if (
                pfxPassphrase &&
                pfxPassphrase !== credentialToUpdate.pfxPassphrase
              ) {
                updatedCredential.pfxPassphrase = pfxPassphrase
              }

              await updateCredentialMutation.mutateAsync(updatedCredential)
              setIsUpdateCredentialModalOpen(false)
            }
          }}
        />
      )}

      <DeleteOrgCredentialModal
        isModalOpen={isDeleteCredentialModalOpen}
        credentialId={credentialIdToDelete}
        onCancel={() => setIsDeleteCredentialModalOpen(false)}
        onDelete={async (credentialId?: string) => {
          if (!credentialId) {
            return
          }
          await deleteCredentialMutation.mutateAsync(credentialId)
          setIsDeleteCredentialModalOpen(false)
        }}
      />
    </>
  )
}
