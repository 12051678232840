import { parseInt } from 'lodash'
import { isNaN } from 'mathjs'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import IWGenericCard from 'shared/components/thunderbolt/IWGenericCard'
import IWTab from 'shared/components/thunderbolt/IWTab'
import IWTabs from 'shared/components/thunderbolt/IWTabs'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import UserContext from 'shared/contexts/UserContext'
import styled from 'styled-components'
import DefaultPriceInfo from './DefaultPriceInfo'
import LsConfigurations from './LsConfigurations'
import LsCredentials from './LsCredentials'
import QuantityRules from './QuantityRule'
import TargetNetOpenPosition from './TargetNOP'

const PreferencesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const CardContent = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
`

const StyledInfoBlock = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

const Section = ({
  title,
  children,
}: {
  title?: string
  children: ReactNode
}) => {
  return (
    <PreferencesSection>
      {title && (
        <IWTypography size="lg" weight="medium">
          {title}
        </IWTypography>
      )}
      <IWGenericCard>
        <CardContent>
          <StyledInfoSection>
            <StyledInfoBlock>{children}</StyledInfoBlock>
          </StyledInfoSection>
        </CardContent>
      </IWGenericCard>
    </PreferencesSection>
  )
}

const LoadSchedulingSettingPage = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)
  const user = useContext(UserContext)
  const isAdmin = user.availableToolPolicies.includes('system:admin')

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const queryParamsTab = searchParams.get('tab')

    if (queryParamsTab === null) {
      searchParams.delete('tab')
      setSearchParams(searchParams)
      return
    }

    if (!isNaN(Number(queryParamsTab))) {
      const tabNumber = parseInt(queryParamsTab)
      setActiveTab(tabNumber)
    }
  }, [searchParams])

  return (
    <>
      <IWTabs
        activeTabIndex={activeTab}
        onTabChange={(tabIndex) => {
          searchParams.set('tab', tabIndex.toString())
          setActiveTab(tabIndex)
          setSearchParams(searchParams)
        }}
      >
        <IWTab label={t('loadScheduling.markets.ercot')} />
        <IWTab label={t('loadScheduling.markets.pjm')} disabled />
        <IWTab label={t('loadScheduling.markets.neiso')} disabled />
        <IWTab label={t('loadScheduling.markets.nyiso')} disabled />
        <IWTab label={t('loadScheduling.markets.miso')} disabled />
        {isAdmin ? (
          <IWTab label={t('loadScheduling.tabConfigsTitle')} />
        ) : (
          <></>
        )}
        {isAdmin ? (
          <IWTab label={t('loadScheduling.tabCredentialsTitle')} />
        ) : (
          <></>
        )}
      </IWTabs>

      {activeTab === 0 && (
        <>
          <Section>
            <QuantityRules />
          </Section>

          <Section>
            <TargetNetOpenPosition />
          </Section>

          <Section>
            <DefaultPriceInfo />
          </Section>
        </>
      )}

      {activeTab === 5 && <LsConfigurations />}
      {activeTab === 6 && <LsCredentials />}
    </>
  )
}

export default LoadSchedulingSettingPage
