import { faBars, faBell } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import useAuthenticationFlow from 'root/hooks/useAuthenticationFlow'
import { useElementHeight } from 'root/hooks/useElementHeight'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWVerticalNavItem from 'shared/components/thunderbolt/IWVerticalNavItem'
import LayoutContext from 'shared/contexts/LayoutContext'
import styled, { ThemeContext } from 'styled-components'
import IWSettingTile from './IWSettingTile'

const StyledHeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.palette.others.sidebarHeaderBg};
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: ${(props) => props.theme.layers.fixed};
  left: 0;
  right: 0;
  top: 0;
  padding: 0.75rem 1rem 0.75rem 0.75rem;
`

const StyledBrandingWrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled('button')`
  border: none;
  background: transparent;
  cursor: pointer;
  align-self: flex-end;
  font-size: 1rem;
  color: ${(props) => props.theme.palette.grey[900]};
  margin: 0 1rem 0 1rem;

  & :hover {
    color: ${(props) => darken(0.1, props.theme.palette.grey[900])};
  }
`

const StyledNotificationIcon = styled.div`
  width: auto;
`

const NotificationsButton = () => {
  const { isNotificationListMenuOpen, setIsNotificationListMenuOpen } =
    useContext(LayoutContext)

  return (
    <StyledNotificationIcon>
      <IWVerticalNavItem
        label=""
        icon={faBell}
        onClick={() => {
          setIsNotificationListMenuOpen(!isNotificationListMenuOpen)
        }}
      />
    </StyledNotificationIcon>
  )
}

export const TopbarHeader = ({ logoUrl }: { logoUrl: string }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    elementRef: headerRef,
    height: headerHeight,
    pixelsToRem,
  } = useElementHeight()
  const { logout: onLogout } = useAuthenticationFlow(navigate)
  const { toggleMenu, setTopbarHeaderHeight } = useContext(LayoutContext)
  const {
    palette: {
      others: { brandingText },
    },
  } = useContext(ThemeContext)

  setTopbarHeaderHeight(`${pixelsToRem(headerHeight)}rem`)

  return (
    <StyledHeaderWrapper ref={headerRef} data-testid="top-bar-header">
      <StyledBrandingWrapper>
        <div>
          <IconWrapper
            onClick={toggleMenu}
            data-testid="horizontal-nav-hamburger"
          >
            <FontAwesomeIcon icon={faBars} />
          </IconWrapper>
        </div>

        <img data-testid="horizontal-nav-logo" src={logoUrl} alt="Logo" />

        {brandingText && (
          <IWTypography size="lg">&nbsp;{t(brandingText)}</IWTypography>
        )}
      </StyledBrandingWrapper>

      <div style={{ display: 'flex' }}>
        <NotificationsButton />

        <IWSettingTile onLogout={onLogout} />
      </div>
    </StyledHeaderWrapper>
  )
}

export default TopbarHeader
