import {
  faCirclePlus,
  faEllipsisVertical,
  faFingerprint,
  faTrashCanCheck,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { orderBy } from 'lodash'
import {
  ReactNode,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWContextMenu from 'shared/components/thunderbolt/IWContextMenu'
import IWContextMenuItem from 'shared/components/thunderbolt/IWContextMenuItem'
import IWContextDivider from 'shared/components/thunderbolt/IWContextMenuItemDivider'
import IWTable from 'shared/components/thunderbolt/IWTable'
import IWToggle from 'shared/components/thunderbolt/IWToggle'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import UserContext from 'shared/contexts/UserContext'
import {
  createOrgConfig,
  deleteOrgConfig,
  getConfigIds,
  toggleActiveOrgConfig,
} from 'shared/loadSchedulingClient'
import styled from 'styled-components'
import CreateOrgConfigModal from 'systemPreferences/components/LoadScheduling/CreateOrgConfigModal'
import DeleteOrgConfigModal from 'systemPreferences/components/LoadScheduling/DeleteOrgConfigModal'

const StyledDesc = styled(IWTypography)`
  text-align: left;
  color: ${(props) => props.theme.palette.grey[400]};
  margin-bottom: 1rem;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionHeader = styled(IWTypography)`
  display: inline-block;
`

const HeaderWithButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

type Props = {
  header: string
  buttonLabel: string
  onButtonClick: (e: SyntheticEvent) => void
  children: ReactNode
}

const SectionHeaderComponent = ({
  header,
  buttonLabel,
  onButtonClick,
  children,
}: Props) => {
  return (
    <Section>
      <HeaderWithButton>
        <SectionHeader
          as="h1"
          size="lg"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
        >
          {header}
        </SectionHeader>
        <IWButton
          icon={faCirclePlus}
          iconPosition="leading"
          onClick={onButtonClick}
        >
          {buttonLabel}
        </IWButton>
      </HeaderWithButton>
      {children}
    </Section>
  )
}

const tableColumns = [
  {
    title: 'Market',
    accessor: 'market',
    align: 'left',
    sortable: true,
  },
  {
    title: 'Brand',
    accessor: 'brand',
    align: 'left',
    sortable: true,
  },
  {
    title: 'Enable',
    accessor: 'enabled',
    align: 'center',
  },
  {
    title: 'Actions',
    accessor: 'contextMenu',
    align: 'center',
  },
]

export const LsConfigurations = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [items, setItems] = useState<
    {
      market: any
      brand: any
      enabled: JSX.Element
      contextMenu: JSX.Element
    }[]
  >([])
  const [configIdToDelete, setConfigIdToDelete] = useState<string | undefined>(
    undefined,
  )
  const [isCreateConfigModalOpen, setIsCreateConfigModalOpen] = useState(false)
  const [isDeleteConfigModalOpen, setIsDeleteConfigModalOpen] = useState(false)
  const { organization } = useContext(UserContext)

  const { data: configs, isLoading } = useQuery({
    queryKey: ['ls-configs'],
    queryFn: async () => {
      return getConfigIds(true)
    },
  })

  const mutation = useMutation({
    mutationKey: ['ls-configs', 'toggle-enable'],
    mutationFn: ({
      configId,
      isActive,
    }: {
      configId: string
      isActive: boolean
    }) => {
      return toggleActiveOrgConfig(configId, isActive)
    },
    onSuccess: () => queryClient.invalidateQueries(['ls-configs']),
  })

  const createConfigMutation = useMutation({
    mutationKey: ['ls-configs', 'create'],
    mutationFn: ({ market, brand }: { market: string; brand: string }) =>
      createOrgConfig({ market, brand, org: organization }),
    onSuccess: () => queryClient.invalidateQueries(['ls-configs']),
  })

  const deleteConfigMutation = useMutation({
    mutationKey: ['ls-configs', 'create'],
    mutationFn: (configId: string) => deleteOrgConfig(configId),
    onSuccess: () => queryClient.invalidateQueries(['ls-configs']),
  })

  const mapper = () => {
    return orderBy(configs || [], ['market', 'brand']).map((item, index) => ({
      market: item.market,
      brand: item.brand,
      enabled: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IWToggle
            id={index.toString()}
            checked={item.isActive}
            onChange={async () => {
              await mutation.mutateAsync({
                configId: item.configId,
                isActive: !item.isActive,
              })
            }}
          />
        </div>
      ),
      contextMenu: (
        <IWContextMenu
          positions={['bottom', 'top']}
          menu={[
            <IWContextMenuItem
              as="routerLink"
              key={`ls-config-view-creds-${item.configId}`}
              data-testid={`ls-config-view-creds-${item.configId}`}
              label={t('loadScheduling.contextMenus.viewCredentials')}
              icon={faFingerprint}
              to={`/system-preferences/load-scheduling?tab=6&configId=${item.configId}`}
            />,

            <IWContextDivider />,

            <IWContextMenuItem
              as="button"
              key={`ls-config-delete-${item.configId}`}
              data-testid={`ls-config-delete-${item.configId}`}
              label={t('button.delete')}
              icon={faTrashCanCheck}
              disabled={item.isActive}
              onClick={() => {
                setConfigIdToDelete(item.configId)
                setIsDeleteConfigModalOpen(true)
              }}
            />,
          ]}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </IWContextMenu>
      ),
    }))
  }

  useEffect(() => {
    searchParams.delete('configId')
    setSearchParams(searchParams)
  }, [])

  useEffect(() => {
    setItems(mapper() || [])
  }, [configs])

  return (
    <>
      <SectionHeaderComponent
        header={t('loadScheduling.configurationsPage.title')}
        buttonLabel={t('loadScheduling.configurationsPage.addConfiglabel')}
        onButtonClick={() => {
          setIsCreateConfigModalOpen(true)
        }}
      >
        <StyledDesc size="sm">
          {t('loadScheduling.configurationsPage.desc')}
        </StyledDesc>

        {Boolean(items?.length) && (
          <IWTable
            border
            isLoading={isLoading}
            data={mapper() || []}
            columns={tableColumns}
          />
        )}
      </SectionHeaderComponent>

      <CreateOrgConfigModal
        isModalOpen={isCreateConfigModalOpen}
        onCancel={() => setIsCreateConfigModalOpen(false)}
        onAddConfig={async (market: string, brand: string) => {
          await createConfigMutation.mutateAsync({ market, brand })
          setIsCreateConfigModalOpen(false)
        }}
      />

      <DeleteOrgConfigModal
        isModalOpen={isDeleteConfigModalOpen}
        configId={configIdToDelete}
        onCancel={() => setIsDeleteConfigModalOpen(false)}
        onDelete={async (configId?: string) => {
          if (!configId) {
            return
          }

          await deleteConfigMutation.mutateAsync(configId)
          setIsDeleteConfigModalOpen(false)
        }}
      />
    </>
  )
}

export default LsConfigurations
