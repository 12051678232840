import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import styled from 'styled-components'

const StyledModalFooter = styled(IWModalFooter)`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`

type Props = {
  isModalOpen: boolean
  credentialId?: string
  onCancel: () => void
  onDelete: (configId?: string) => void
}

const DeleteOrgCredentialModal = ({
  isModalOpen,
  credentialId,
  onCancel,
  onDelete,
}: Props) => {
  const { t } = useTranslation()

  const handleOnClose = () => {
    onCancel()
  }

  const handleOnDelete = () => {
    onDelete(credentialId)
  }

  return (
    <IWModal open={isModalOpen} onClose={handleOnClose}>
      <IWModalHeader>
        <IWTypography size="lg" weight="medium">
          {t('loadScheduling.credentialsPage.deleteModalLabel')}
        </IWTypography>
      </IWModalHeader>
      <IWModalContent>
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 400 }}>
          {t('loadScheduling.credentialsPage.deleteModalDesc', {
            credentialId,
          })}
        </IWTypography>
      </IWModalContent>
      <StyledModalFooter>
        <IWButton color="grey" variant="outline" onClick={handleOnClose}>
          {t('button.cancel')}
        </IWButton>
        <IWButton
          color="alert"
          disabled={!credentialId}
          onClick={handleOnDelete}
        >
          {t('button.delete')}
        </IWButton>
      </StyledModalFooter>
    </IWModal>
  )
}

export default DeleteOrgCredentialModal
