import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import IWToggle from 'shared/components/thunderbolt/IWToggle'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { OrgCredential } from 'shared/loadSchedulingClient'
import styled from 'styled-components'

type ModalProps = {
  isModalOpen: boolean
  credentialToUpdate: OrgCredential
  onCancel: () => void
  onCreate: (credential: UpdateOrgCredential) => void
}

export type UpdateOrgCredential = Pick<
  OrgCredential,
  | 'credentialId'
  | 'zone'
  | 'location'
  | 'locationName'
  | 'userId'
  | 'userAccount'
  | 'userPassword'
  | 'pfx'
  | 'pfxPassphrase'
  | 'isSuma'
  | 'isActive'
>

const StyledModalFooter = styled(IWModalFooter)`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`

const Spacer = styled.div`
  margin: 1rem;
`

const UpdateOrgCredentialModal = ({
  isModalOpen,
  credentialToUpdate,
  onCancel,
  onCreate,
}: ModalProps) => {
  const { t } = useTranslation()
  const [zone, setZone] = useState(credentialToUpdate.zone)
  const [location, setLocation] = useState(credentialToUpdate.location)
  const [locationName, setLocationName] = useState(
    credentialToUpdate.locationName,
  )
  const [userId, setUserId] = useState(credentialToUpdate.userId)
  const [userAccount, setUserAccount] = useState(credentialToUpdate.userAccount)
  const [userSubAccount, setUserSubAccount] = useState(
    credentialToUpdate.userSubAccount,
  )
  const [userPassword, setUserPassword] = useState(
    credentialToUpdate.userPassword,
  )
  const [pfx, setPFX] = useState(credentialToUpdate.pfx)
  const [pfxPassphrase, setPFXPassphrase] = useState(
    credentialToUpdate.pfxPassphrase,
  )
  const [isSuma, setIsSuma] = useState(credentialToUpdate.isSuma)
  const [isActive, setIsActive] = useState(credentialToUpdate.isActive)

  const handleReset = () => {
    setZone('')
    setLocation('')
    setLocationName('')
    setUserId('')
    setUserAccount('')
    setUserSubAccount('')
    setUserPassword('')
    setPFX('')
    setPFXPassphrase('')
    setIsSuma(false)
    setIsActive(false)
  }

  const handleClose = () => {
    onCancel()
  }

  const hasErrors = () => {
    if (!zone || !zone.length) {
      return true
    }

    if (!location || !location.length) {
      return true
    }

    if (!locationName || !locationName.length) {
      return true
    }

    return false
  }

  const handleCreate = () => {
    if (hasErrors()) {
      return
    }

    const credential = {
      credentialId: credentialToUpdate.credentialId,
      zone,
      location,
      locationName,
      userId,
      userAccount,
      userPassword,
      userSubAccount,
      pfx,
      pfxPassphrase,
      isSuma,
      isActive,
    } as UpdateOrgCredential

    onCreate(credential)
    onCancel()
  }

  useEffect(() => {
    setZone(credentialToUpdate.zone)
    setLocation(credentialToUpdate.location)
    setLocationName(credentialToUpdate.locationName)
    setUserId(credentialToUpdate.userId)
    setUserAccount(credentialToUpdate.userAccount)
    setUserSubAccount(credentialToUpdate.userSubAccount)
    setUserPassword(credentialToUpdate.userPassword)
    setPFX(credentialToUpdate.pfx)
    setPFXPassphrase(credentialToUpdate.pfxPassphrase)
    setIsSuma(credentialToUpdate.isSuma)
    setIsActive(credentialToUpdate.isActive)

    return () => {
      handleReset()
    }
  }, [credentialToUpdate])

  return (
    <IWModal open={isModalOpen} onClose={onCancel}>
      <IWModalHeader>
        <IWTypography size="lg" weight="medium">
          {t('loadScheduling.credentialsPage.updateModalLabel')}
        </IWTypography>
      </IWModalHeader>

      <IWModalContent>
        <IWTextInput
          label={t('loadScheduling.credentialsPage.addModalZoneLabel')}
          required
          hasError={!zone || !zone.length}
          value={zone}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setZone(e.target.value)
          }
        />

        <Spacer />

        <IWTextInput
          label={t('loadScheduling.credentialsPage.addModalLocationNameLabel')}
          required
          hasError={!locationName || !locationName.length}
          value={locationName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLocationName(e.target.value)
          }
        />

        <Spacer />

        <IWTextInput
          label={t('loadScheduling.credentialsPage.addModalLocationLabel')}
          required
          hasError={!location || !location.length}
          value={location}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLocation(e.target.value)
          }
        />

        <Spacer />

        <IWTextInput
          label={t('loadScheduling.credentialsPage.addModalUserIdLabel')}
          value={userId}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserId(e.target.value)
          }
        />

        <Spacer />

        <IWTextInput
          label={t('loadScheduling.credentialsPage.addModalUserAccountLabel')}
          value={userAccount || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserAccount(e.target.value)
          }
        />

        <Spacer />

        <IWTextInput
          label={t(
            'loadScheduling.credentialsPage.addModalUserSubAccountLabel',
          )}
          value={userSubAccount}
          placeholder="DEFAULT"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserSubAccount(e.target.value)
          }
        />

        <Spacer />

        <IWTextInput
          label={t('loadScheduling.credentialsPage.addModalUserPasswordLabel')}
          value={userPassword || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserPassword(e.target.value)
          }
        />

        <Spacer />

        <IWTextInput
          label={t('loadScheduling.credentialsPage.addModalPFXLabel')}
          value={pfx}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPFX(e.target.value)
          }
        />

        <Spacer />

        <IWTextInput
          label={t('loadScheduling.credentialsPage.addModalPFXPassphraseLabel')}
          value={pfxPassphrase}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPFXPassphrase(e.target.value)
          }
        />

        <Spacer />

        <IWToggle
          id={`ls-update-cred-is-suma-${credentialToUpdate.credentialId}`}
          label={t('loadScheduling.credentialsPage.addModalIsSUMALabel')}
          checked={isSuma}
          onChange={() => {
            setIsSuma((p) => !p)
          }}
        />
        <Spacer />

        <IWToggle
          id={`ls-update-cred-is-active-${credentialToUpdate.credentialId}`}
          label={t('loadScheduling.credentialsPage.addModalIsActiveLabel')}
          checked={isActive}
          onChange={() => {
            setIsActive((p) => !p)
          }}
        />

        <Spacer />
      </IWModalContent>
      <StyledModalFooter>
        <IWButton color="grey" variant="outline" onClick={handleClose}>
          {t('button.cancel')}
        </IWButton>
        <IWButton onClick={handleCreate} disabled={hasErrors()}>
          {t('button.create')}
        </IWButton>
      </StyledModalFooter>
    </IWModal>
  )
}

export default UpdateOrgCredentialModal
